<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active h-100"
  >
    <!--begin::SubIn-->
    <b-card class="h-100">
      <div class="row justify-content-between align-self-center mb-3">
        <div>
          <h3 class="text-primary mb-1">{{ titlePage.title }}</h3>
          <span class="text-muted"
            >Showing {{ parseInt(this.params.offset) + 1 }} to
            {{ parseInt(this.params.offset) + parseInt(params.limit) }} of
            {{ totalCount }} entries</span
          >
        </div>
        <div class="row no-gutters" style="gap: 8px">
          <b-form-group class="mb-0">
            <div class="input-icon">
              <input
                v-model="params.query"
                type="text"
                class="form-control"
                placeholder="Search..."
              />
              <span><i class="flaticon2-search-1 icon-md"></i></span>
            </div>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-form-select
              v-model="params.order"
              :options="optionSort"
            ></b-form-select>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-button
              variant="primary"
              class="py-1 px-2 font-weight-bold"
              id="detail"
              title="tolak"
              @click="handleExport"
              style="height: 38px; padding: 0 16px !important"
            >
              Export
            </b-button>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-button
              variant="primary"
              class="py-1 px-2 font-weight-bold"
              id="detail"
              title="tolak"
              @click="handleExportCSV"
              style="height: 38px; padding: 0 16px !important"
              v-if="statusSelected === 'processing'"
            >
              Export to CSV
            </b-button>
          </b-form-group>
          <!-- <b-form-group class="mb-0">
            <b-form-select
              v-model="params.order"
              :options="optionSort"
            ></b-form-select>
          </b-form-group> -->
        </div>
      </div>
      <div
        class="h-100 d-flex pb-8"
        style="flex-direction: column; justify-content: space-between"
      >
        <div class="table-responsive">
          <b-table
            hover
            :items="tableData"
            :fields="fields"
            :per-page="params.limit"
            :current-page="refCurrentPage"
            :busy="isLoading"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-muted my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
            <template #cell(loan_size.amount)="row">
              {{ formatRupiah(row.item.loan_size.amount) }}
            </template>
            <template #cell(status)="row">
              <div class="sub-status" v-if="row.item.status === 'pending'">
                <i class="menu-icon flaticon-list-1 pr-0"></i>
                Pengajuan Baru
              </div>
              <div
                class="sub-status"
                v-else-if="row.item.status === 'processing'"
              >
                <i class="menu-icon flaticon2-checkmark pr-0"></i>
                Diproses
              </div>
              <div
                class="sub-status"
                v-else-if="row.item.status === 'rejected'"
              >
                <i class="menu-icon flaticon-cancel pr-0"></i>
                Dibatalkan
              </div>
              <div
                class="sub-status"
                v-else-if="row.item.status === 'canceled'"
              >
                <i class="menu-icon flaticon-cancel pr-0"></i>
                Ditolak
              </div>
              <div
                class="sub-status"
                v-else-if="row.item.status === 'transferred'"
              >
                <i class="menu-icon flaticon2-send-1 pr-0"></i>
                Sudah Transfer
              </div>
              <div
                class="sub-status"
                v-else-if="row.item.status === 'finished'"
              >
                <i class="menu-icon flaticon2-check-mark pr-0"></i>
                Selesai
              </div>
            </template>
            <template #cell(action)="row">
              <div v-if="row.item.status === 'pending'">
                <b-button
                  variant="success"
                  class="py-1 px-2 font-weight-bold mr-1"
                  id="detail"
                  title="proses"
                  @click="handleProcess(row.item.id)"
                >
                  <i class="menu-icon flaticon2-checkmark"></i>
                  Proses
                </b-button>
                <b-button
                  variant="danger"
                  class="py-1 px-2 font-weight-bold"
                  id="detail"
                  title="tolak"
                  @click="handleCancel(row.item.id)"
                >
                  <i class="menu-icon flaticon-cancel pr-1"></i>
                  Tolak
                </b-button>
              </div>
              <div v-else-if="row.item.status === 'processing'">
                <b-button
                  variant="primary"
                  class="py-1 px-2 font-weight-bold mr-1"
                  id="detail"
                  title="transfer"
                  @click="handleSend(row.item.id)"
                >
                  <i class="menu-icon flaticon2-send-1 pr-1"></i>
                  Transfer
                </b-button>
                <b-button
                  variant="danger"
                  class="py-1 px-2 font-weight-bold"
                  id="detail"
                  title="tolak"
                  @click="handleReject(row.item.id)"
                >
                  <i class="menu-icon flaticon-cancel pr-1"></i>
                  Batalkan
                </b-button>
              </div>
              <div v-else-if="row.item.status === 'transferred'">
                <b-button
                  variant="info"
                  class="py-1 px-2 font-weight-bold"
                  id="detail"
                  title="selesaikan"
                  @click="handleFinish(row.item.id)"
                >
                  <i class="menu-icon flaticon2-check-mark pr-1"></i>
                  Selesaikan
                </b-button>
              </div>
              <div v-else-if="row.item.status === 'finished'">
                <b-button
                  variant="secondary"
                  class="py-1 px-2 font-weight-bold"
                  id="detail"
                  title="selesaikan"
                  @click="handlePrint(row.item.id)"
                >
                  <i class="menu-icon flaticon2-print pr-1"></i>
                  Cetak
                </b-button>
              </div>
            </template>
          </b-table>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-1">
            <b-form-group class="mb-0">
              <b-form-select
                id="per-page-select"
                v-model="params.limit"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-pagination
              v-model="refCurrentPage"
              :total-rows="totalCount"
              :per-page="params.limit"
              size="md"
              align="fill"
              class="ml-auto"
              style="padding: 0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </b-card>
    <!--end::SubIn-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { formatRupiah } from "@/utils/helper";
import _ from "lodash";

export default {
  name: "ListSubmissionInLoan",
  data() {
    const { query, sort, order, limit, offset } = this.$route.query;
    const fields = [
      {
        key: "referrence_number",
        label: "Kode Pengajuan",
        align: "left",
        sortBy: "",
        sortByFormatted: true,
        filterByFormatted: true,
        // sortable: true,
      },
      {
        key: "created_at",
        label: "Tanggal Pengajuan",
        align: "left",
        sortBy: "",
        // sortable: true,
      },
      {
        key: "employee.name",
        label: "Nama Karyawan",
        align: "left",
        sortBy: "",
        // sortable: true,
      },
      {
        key: "employee.bank_account.account_number",
        label: "No Rekening",
        align: "center",
        width: "10%",
      },
      {
        key: "loan_size.amount",
        label: "Nilai Pengajuan",
        align: "center",
        width: "10%",
      },
      {
        key: "status",
        label: "Status Pengajuan",
        align: "center",
        width: "10%",
      },
      {
        key: "employee.nip",
        label: "NIP",
        align: "center",
      },
      {
        key: "employee.customer_branch.address",
        label: "Lokasi Penempatan",
        align: "center",
      },
      {
        key: "employee.customer_branch.name",
        label: "Site",
        align: "center",
      },
      {
        key: "action",
        align: "center",
        stickyColumn: true,
      },
    ];
    return {
      // Table Req
      selected: null,
      isLoading: false,
      currentPage: 1,
      pageOptions: [10, 15, 100],
      optionSort: [
        { value: "desc", text: "Terbaru" },
        { value: "asc", text: "Terlama" },
      ],
      optionTotal: [
        { value: "desc", text: "Jumlah" },
        { value: "asc", text: "Terlama" },
      ],
      fields,
      sortBy: "",
      sortDesc: true,
      sortDirection: "asc",
      tableData: [],
      totalCount: 0,
      params: {
        query: query ?? null,
        sort: sort ?? "created_at",
        order: order ?? "desc",
        limit: limit ?? 10,
        offset: offset ?? 0,
      },
      titlePage: "",
      //-----//
    };
  },
  components: {},
  computed: {
    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.params.offset = (value - 1) * this.params.limit;
      },
    },
    paramsComp() {
      return JSON.parse(JSON.stringify(this.params));
    },
    statusSelected() {
      return this.$route.path.split("/")[3];
    },
  },
  mounted() {
    this.getData();
    this.changeTitle();
  },
  methods: {
    formatRupiah,
    changeTitle() {
      this.titlePage = { title: "" };
      if (this.statusSelected === "pending") {
        this.titlePage = { title: "Pengajuan Masuk" };
      }
      if (this.statusSelected === "processing") {
        this.titlePage = { title: "Pengajuan Diproses" };
      }
      if (this.statusSelected === "transferred") {
        this.titlePage = { title: "Pengajuan Ditransfer" };
      }
      if (this.statusSelected === "canceled") {
        this.titlePage = { title: "Pengajuan Ditolak" };
      }
      if (this.statusSelected === "finished") {
        this.titlePage = { title: "Pengajuan Selesai" };
      }
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        this.titlePage,
      ]);
    },
    navigateUrl() {
      const { query, sort, order, limit, offset } = this.params;
      const routeParams = { query, sort, order, limit, offset };
      if (JSON.stringify(this.$route.query) !== JSON.stringify(routeParams)) {
        this.$router.push({
          path: `/fund/submission/${this.statusSelected}`,
          query: routeParams,
        });
      }
    },
    getData: _.debounce(async function () {
      try {
        this.isLoading = true;
        this.navigateUrl();
        await this.$axios
          .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/loan`, {
            params: { status: this.statusSelected, ...this.params },
          })
          .then(({ data }) => {
            this.tableData = data.loans;
            this.totalCount = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response?.data?.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    }, 500),
    handleProcess(id) {
      this.$swal({
        title:
          "<strong>Apakah Anda yakin untuk memproses pengajuan ini?</strong>",
        showCancelButton: true,
        icon: "info",
        html:
          "If you need more info, weel done please great and outstanting apps and check" +
          '<a href="//sweetalert2.github.io"> read our FAQ</a> ',
        cancelButtonText: "Batal",
        confirmButtonText: "Proses",
        confirmButtonColor: "#26519F",
        heightAuto: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateStatusLoan(id, "processing");
        }
      });
    },
    handleCancel(id) {
      this.$swal({
        title:
          "<strong>Apakah Anda yakin untuk menolak pengajuan ini?</strong>",
        icon: "warning",
        html:
          "If you need more info, weel done please great and outstanting apps and check" +
          '<a href="//sweetalert2.github.io"> read our FAQ</a> ',
        showCancelButton: true,
        showDenyButton: true,
        showConfirmButton: false,
        denyButtonText: `Tolak`,
        heightAuto: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isDenied) {
          this.updateStatusLoan(id, "canceled");
        }
      });
    },
    handleReject(id) {
      this.$swal({
        title:
          "<strong>Apakah Anda yakin untuk membatalkan pengajuan ini?</strong>",
        icon: "warning",
        showCancelButton: true,
        showDenyButton: true,
        showConfirmButton: false,
        denyButtonText: `Batalkan`,
        heightAuto: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isDenied) {
          this.updateStatusLoan(id, "rejected");
        }
      });
    },
    handleSend(id) {
      this.$swal({
        title: "Transfer Pengajuan ini ?",
        showCancelButton: true,
        confirmButtonText: "Transfer",
        confirmButtonColor: "#65B8FF",
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateStatusLoan(id, "transferred");
        }
      });
    },
    handleFinish(id) {
      this.$swal({
        title: "Selesaikan Pengajuan ini ?",
        showCancelButton: true,
        confirmButtonText: "Selesaikan & Cetak",
        confirmButtonColor: "#26519F",
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateStatusLoan(id, "finished");
          this.handlePrint(id);
        }
      });
    },
    handlePrint(id) {
      this.$swal({
        title: "Cetak pengajuan ini?",
        showCancelButton: true,
        confirmButtonText: "Cetak",
        confirmButtonColor: "#26519F",
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.downloadPDF(id);
        }
      });
    },
    async downloadPDF(id) {
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/loan/download/${id}`
        );
        window.open(data.file);
      } catch ({ response }) {
        this.$bvToast.toast(response?.data?.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    async handleExport() {
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/loan/export`,
          { params: { ...this.params, status: this.statusSelected } }
        );
        window.open(data.file);
      } catch ({ response }) {
        this.$bvToast.toast(response?.data?.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    async handleExportCSV() {
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/loan/export/processing?`,
          { params: { ...this.params } }
        );
        window.open(data.file);
      } catch ({ response }) {
        this.$bvToast.toast(response?.data?.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    async updateStatusLoan(id, status) {
      try {
        await this.$axios.put(
          `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/loan/status/${id}`,
          { status }
        );
        this.getData();
      } catch ({ response }) {
        this.$bvToast.toast(response?.data?.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.path !== oldValue.path) {
        this.params = {
          query: null,
          sort: "created_at",
          order: "desc",
          limit: 10,
          offset: 0,
        };
        this.getData();
        this.changeTitle();
      }
    },
    paramsComp: {
      deep: true,
      handler: _.debounce(function (newValue, oldValue) {
        if (newValue.query !== oldValue.query) {
          this.params.offset = 0;
        }
        this.getData();
        this.changeTitle();
      }, 400),
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-status {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 4px 8px;
  color: #89909a;
  width: fit-content;
  border-radius: 8px;
  font-weight: 500;
  gap: 8px;
  i {
    color: #89909a;
  }
}
</style>
